import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
	render() {
		return (
			<div className="section-body">
				<footer className="footer">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-6 col-sm-12">
								Copyright © 2024 <a href="#">MU University</a>.
                            </div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}
